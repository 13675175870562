import "./styles.scss"

import React from "react"

import LazyFadeImage from "components/LazyFadeImage"

const Grid = () => {
  return (
    <section className="developer-management-grid">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-xl-5 col-md-6">
            <LazyFadeImage>
              <div
                className="developer-management-grid__image developer-management-grid__image--2"
                style={{
                  backgroundImage: `url('${
                    require("assets/images/developer/developer-management-hero-image-2.jpg")
                      .default
                  }')`,
                }}
              />
            </LazyFadeImage>
          </div>
          <div className="col-xl-5 offset-xl-1 col-md-6">
            <h2>Poznaj naszych partnerów</h2>
            <p>
              Współpracujemy z doświadczonymi operatorami realizującymi
              kompleksowe usługi w zakresie wykończenia i wyposażenia
              apartamentu oraz zarządzania nieruchomościami premium. Naszym
              klientom dajemy możliwość maksymalizacji zysków przy ich
              minimalnym zaangażowaniu.
            </p>
            <p>
              Międzynarodowy zespół ekspertów zajmie się obsługą apartamentów na
              wyłączność, dostosowanymi do określonego, wysokiego standardu
              gwarantując za każdym razem produkt z najwyższej półki. Firma
              oferuje usługi w zakresie zarządzania nieruchomościami w
              perspektywie krótko-, średnio- i długoterminowej. Dąży do
              zwiększenia stopy zwrotu przy jednoczesnym zapewnieniu stabilnych
              warunków.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Grid
