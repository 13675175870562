import "./styles.scss"

import React from "react"

import LazyFadeImage from "components/LazyFadeImage"

const Services = () => {
  const data = [
    {
      icon: require("assets/images/developer/developer-management-services-ico-1.svg")
        .default,
      header: "Home Staging i projektowanie wnętrz",
    },
    {
      icon: require("assets/images/developer/developer-management-services-ico-2.svg")
        .default,
      header: "Profesjonalny marketing ofert",
    },
    {
      icon: require("assets/images/developer/developer-management-services-ico-3.svg")
        .default,
      header: "Aplikacja dla właścicieli",
    },
    {
      icon: require("assets/images/developer/developer-management-services-ico-4.svg")
        .default,
      header: "Recepcja 24h/7",
    },
    {
      icon: require("assets/images/developer/developer-management-services-ico-5.svg")
        .default,
      header: "Profesjonalne utrzymanie czystości",
    },
    {
      icon: require("assets/images/developer/developer-management-services-ico-6.svg")
        .default,
      header: "Tekstylia hotelowe",
    },
    {
      icon: require("assets/images/developer/developer-management-services-ico-7.svg")
        .default,
      header: "Obsługa techniczna i drobne naprawy",
    },
    {
      icon: require("assets/images/developer/developer-management-services-ico-8.svg")
        .default,
      header: "Multikanałowa sprzedaż i monitoring zysków online",
    },
  ]

  return (
    <section className="developer-management-services">
      <div className="container-fluid">
        <h2>Dodatkowe usługi, do których Klient zyskuje dostęp:</h2>
        <div className="row justify-content-center">
          {data.map((item, index) => (
            <div className="col-lg-3 col-md-4 col-6" key={index}>
              <div className="developer-management-services__item">
                <div className="developer-management-services__item-icon">
                  <LazyFadeImage>
                    <img src={item.icon} alt="" />
                  </LazyFadeImage>
                </div>
                <h4>{item.header}</h4>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default Services
