import "./styles.scss"

import React from "react"

import LazyFadeImage from "components/LazyFadeImage"

const Hero = () => {
  return (
    <section className="developer-management-hero">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-xl-5 col-md-6">
            <h2>Zwiększ atrakcyjność swojego projektu</h2>
            <p>
              Poza lokalizacją, standardem i ceną, coraz większą rolę przy
              zakupie apartamentu odgrywa kompleksowa obsługa w zakresie
              wykończenia lokalu oraz zarządzania najmem. Klient inwestycyjny,
              który najczęściej zakupuje apartament w miejscowości turystycznej
              to często taki, który nie mieszka w regionie, w którym nabywa
              nieruchomość. Dodatkowe usługi okazują się więc często niezwykle
              istotne i wpływają na finalną decyzję odnośnie zakupu.
              Bezobsługową inwestycję cechuje możliwość odebrania wykończonego i
              wyposażonego lokalu, a generowanie zysków jest możliwe dzięki
              współpracy z rekomendowanym, profesjonalnym operatorem.
            </p>
          </div>
          <div className="col-xl-6 offset-xl-1 col-md-6">
            <LazyFadeImage>
              <div
                className="developer-management-hero__image"
                style={{
                  backgroundImage: `url('${
                    require("assets/images/developer/developer-management-hero-image.jpg")
                      .default
                  }')`,
                }}
              />
            </LazyFadeImage>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero
