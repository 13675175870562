import React from "react"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"
import PageHeader from "components/PageHeader"

import {
  Hero,
  Tiles,
  Grid,
  Offer,
  Services,
} from "page_components/developer-management"

const Developers = () => {
  const title =
    "Zarządzanie i wykończenie apartamentu w inwestycji deweloperskiej"
  return (
    <Layout
      seo={{
        title: title,
        description:
          "Zajmujemy się kompleksowym wykańczaniem i zarządzaniem apartamentami, oferując usługi operatora zarządzającego obiektem. Skontaktuj się aby uzyskaj więcej informacji. ",
      }}
    >
      <Breadcrumbs
        title={title}
        parent={{
          title: "Dla deweloperów",
          path: "/dla-deweloperow/",
        }}
      />
      <PageHeader title={title} />
      <Hero />
      <Tiles />
      <Grid />
      <Offer />
      <Services />
    </Layout>
  )
}

export default Developers
