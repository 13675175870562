import "./styles.scss"

import React from "react"

import LazyFadeImage from "components/LazyFadeImage"

const Tiles = () => {
  const data = [
    {
      icon: require("assets/images/developer/developer-management-tiles-icon-1.svg")
        .default,
      header: "Oszczędność czasu",
      description:
        "Klient nie traci czasu na poszukiwanie podwykonawców i kontrolę postępu prac",
    },
    {
      icon: require("assets/images/developer/developer-management-tiles-icon-2.svg")
        .default,
      header: "Komfort",
      description:
        "Przygotowanie apartamentu pod klucz wiąże się |z dużym stresem. Trudne jest również dopilnowanie prac wykończeniowych nie będącna co dzień w miejscowości, w której zakupiono lokal. ",
    },
    {
      icon: require("assets/images/developer/developer-management-tiles-icon-3.svg")
        .default,
      header: "Niższe koszty",
      description:
        "Oferując pakiety wykończeń dla całej inwestycji, podwykonawca może wynegocjować bardziej korzystną ofertę niż klient indywidualny.",
    },
  ]
  return (
    <section className="developer-management-tiles">
      <div className="container-fluid">
        <h2>Bezobsługowość apartamentu to klucz do sukcesu</h2>
        <p className="developer-management-tiles__description">
          Dla 75% klientów Marshall RE gotowa oferta zarządzania i wykończenia
          była jednym z czynników decydujących przy zakupie apartamentu.
          <br />
          Dlaczego jest to tak istotne?
        </p>
        <div className="row justify-content-center">
          {data.map((item, index) => (
            <div className="col-lg-4 col-md-6" key={index}>
              <div
                className={`developer-management-tiles__item developer-management-tiles__item--${index}`}
              >
                <div className="developer-management-tiles__item-icon">
                  <LazyFadeImage>
                    <img src={item.icon} alt="" />
                  </LazyFadeImage>
                </div>
                <h3>{item.header}</h3>
                <p>{item.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default Tiles
