import "./styles.scss"

import React from "react"

import LazyFadeImage from "components/LazyFadeImage"

const Offer = () => {
  const data = [
    {
      icon: require("assets/images/developer/developer-management-offer-ico-1.svg")
        .default,
      header: "Gotowe projekty wnętrz i wykończenia",
    },
    {
      icon: require("assets/images/developer/developer-management-offer-ico-2.svg")
        .default,
      header: "Obsługa wynajmu krótkoterminowego",
    },
    {
      icon: require("assets/images/developer/developer-management-offer-ico-3.svg")
        .default,
      header: "Obsługa wynajmu długoterminowego",
    },
  ]

  return (
    <section className="developer-management-offer">
      <div className="container-fluid">
        <h2>Co podnosi atrakcyjność Twojego projektu?</h2>
        <div className="row justify-content-center">
          {data.map((item, index) => (
            <div className="col-md-4 col-6" key={index}>
              <div className="developer-management-offer__item">
                <div className="developer-management-offer__item-icon">
                  <LazyFadeImage>
                    <img src={item.icon} alt="" />
                  </LazyFadeImage>
                </div>
                <h4>{item.header}</h4>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default Offer
